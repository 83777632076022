// src/mixins/fetchMountainsMixin.js

import {REMOTE_FETCH, TOMORROW_IO_URL} from "@/constants";

// Function to update the offset parameter in the URL
function updateUrlOffset(url, newOffset) {
    // Create a URL object from the URL string
    const urlObj = new URL(url);

    // Set the new value for the offset parameter
    urlObj.searchParams.set('offset', newOffset);

    // Return the updated URL string
    return urlObj.toString();
}
let airTableHeaders = {
    // "Access-Control-Allow-Headers": "X-API-Key",
    // "Content-Type": "application/json",
    "X-API-Key": "AIRTABLE",
};
export default {
    data() {
        return {
            isMountainsLoaded: false,
            mountains: [],
            passes: [],
        };
    },
    methods: {
        async addMountainToFavorites(userId, mountainId) {
            try {
                const favorites = this.user.fields['Favorites'] || [];

                // Check if `mountainId` is not already in favorites, then add it.
                const index = favorites.indexOf(mountainId);
                if (index === -1) {
                    favorites.push(mountainId);
                } else {
                    throw new Error('Mountain ID already in favorites');
                }

                // Update the user's favorites in AirTable using fetch.
                const response = await fetch(
                    `${REMOTE_FETCH}?table=Users/${userId}`,
                    {
                        method: 'PATCH',
                        headers: airTableHeaders,
                        body: JSON.stringify({
                            "fields": {
                                "Favorites": favorites
                            }
                        })
                    }
                );

                // Check if response is okay.
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }

                // Log the response and update the UI accordingly.
                return await response.json();
            } catch (error) {
                console.error('Error adding mountain to favorites: ', error);
            }
        },
        async removeMountainFromFavorites(userId, mountainId) {
            try {
                const favorites = this.user.fields['Favorites']

                // Check if `mountainId` exists in favorites and remove it.
                const index = favorites.indexOf(mountainId);
                if (index > -1) {
                    favorites.splice(index, 1);
                } else {
                    throw new Error('Mountain ID not found in favorites');
                }

                // Update the user's favorites in AirTable using fetch.
                const response = await fetch(
                    `${REMOTE_FETCH}?table=Users/${userId}`,
                    {
                        method: 'PATCH',
                        headers: airTableHeaders,
                        body: JSON.stringify({
                            "fields": {
                                "Favorites": favorites
                            }
                        })
                    }
                );

                // Check if response is okay.
                if (!response.ok) {
                    throw new Error('Network response was not ok' + response.statusText);
                }

                // Log the response and update the UI accordingly.
                const responseData = await response.json();
                return responseData;

            } catch (error) {
                console.error('Error removing mountain: ', error);
            }
        },
        async fetchAllRecords(url, records = []) {
            const response = await fetch(url, {
                // mode: "no-cors",
                headers: airTableHeaders,
            });

            // Check if the response is ok
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();

            // Check if data is not null and has a 'records' property
            if (data && Array.isArray(data.records)) {
                records.push(...data.records.map((record) => ({ id: record.id, ...record.fields })));

                // If there's more data, fetch the next page
                if (data.offset) {
                    const updatedUrl = updateUrlOffset(url, data.offset);
                    return this.fetchAllRecords(updatedUrl, records);
                }
            } else {
                // Handle the case when data is null or doesn't have 'records'
                console.error('Invalid data structure:', data);
                // Optional: Throw an error or handle this case as appropriate for your application
            }

            // Otherwise, return the records
            return records;
        },
        async fetchPasses() {
            let url = `${REMOTE_FETCH}?table=Passes&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc`;
            const response = await fetch(url, {
                headers: airTableHeaders,
            });
            const data = await response.json();
            this.passes.push(...data.records.map((record) => {
                const sortedMountainNames = Array.isArray(record.fields['Mountain Names'])
                    ? record.fields['Mountain Names'].sort()
                    : [];
                const fields = {
                    ...record.fields,
                    'Unique Region Names': record.fields['Unique Region Names'].sort(),
                    'Unique Region Count': record.fields['Unique Region Names'].length,
                    'Mountain Count': sortedMountainNames.length
                };
                return { id: record.id, ...fields };
            }));
        },
        async fetchUphill() {
            let url = `${REMOTE_FETCH}?table=Mountains&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc`;
            // Append the 'view' parameter to your URL
            url += `&view=${encodeURIComponent("Uphill View")}`;
            const response = await fetch(url, {
                headers: airTableHeaders,
            });
            const data = await response.json();
            this.mountains.push(...data.records.map((record) => ({ id: record.id, ...record.fields })));
        },
        async fetchMountains() {
            // URL below has 'none' filter
            let url;
            // None filter:
            // url = `${REMOTE_FETCH}?table=Mountains&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc&filterByFormula=NOT({Pass%20Names}='None')`;
            url = `${REMOTE_FETCH}?table=Mountains&sort%5B0%5D%5Bfield%5D=Name&sort%5B0%5D%5Bdirection%5D=asc`;
            if(this.mountainLimit > 0) {
                url = `${url}&maxRecords=${this.mountainLimit}`
            } else if(this.mountainLimit === -1) {
                url = `${url}&maxRecords=0`
            }
            this.mountains = await this.fetchAllRecords(url);
            this.isMountainsLoaded = true;
        },
        // Take either a number or AirTable ID
        async fetchMountainById(id) {
            try {
                let url = `${REMOTE_FETCH}?table=Mountains`;
                let filterFormula = '';
                // Check if 'id' is a string and starts with "rec"
                if (typeof id === 'string' && id.startsWith('rec')) {
                    filterFormula = `RECORD_ID()='${id}'`;
                }
                // Check if 'id' is a number
                else if (!isNaN(id) && Number.isInteger(Number(id))) {
                    // Assuming you have a field name that stores these numeric IDs, for example, 'NumericID'
                    filterFormula = `{ID}=${id}`;
                }
                // Handle the case where 'id' is neither a 'rec' string nor a number
                else {
                    throw new Error("Invalid ID: ID must be a 'rec' string or an integer.");
                }

                url += `&filterByFormula=${encodeURIComponent(filterFormula)}`;
                // Use 'url' as needed in your subsequent logic
                console.log(url);

                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const data = await response.json();
                    // Assuming the ID is unique, return the first record
                    return data.records[0];
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async fetchMountainsByIds(mountainIds) {
            if (!mountainIds || mountainIds.length === 0) {
                return
            }
            // Construct the URL for fetching mountains by IDs
            let url = `${REMOTE_FETCH}?table=Mountains&filterByFormula=OR(`;
            mountainIds.forEach(id => {
                url += `RECORD_ID()='${id}',`
            });
            url = url.slice(0, -1) + ')'; // Removing the last comma and closing the parenthesis.

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const data = await response.json();
                    return data.records;
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async fetchUserByUniqueId(uniqueId) {
            let url = `${REMOTE_FETCH}?table=Users`;
            try {
                let filterFormula = `({UniqueID} = '${uniqueId}')`;
                url += `&filterByFormula=${encodeURIComponent(filterFormula)}`;

                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const userData = await response.json();
                    // Check if records array is not empty, and return the first record if found
                    if (userData.records && userData.records.length > 0) {
                        return userData.records[0];
                    } else {
                        throw new Error('User not found');
                    }
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async setFavoriteMountain(userId, mountainId) {
            let url = `${REMOTE_FETCH}?table=Users/${userId}`;
            try {
                let filterFormula = `({Favorites} = '${mountainId}')`;
                url += `&filterByFormula=${encodeURIComponent(filterFormula)}`;

                const response = await fetch(url, {
                    method: 'PATCH',
                    headers: airTableHeaders,
                });

                if (response.ok) {
                    const userData = await response.json();
                    // Check if records array is not empty, and return the first record if found
                    if (userData.records && userData.records.length > 0) {
                        return userData.records[0];
                    } else {
                        throw new Error('User not found');
                    }
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        async fetchUser(userId) {
            const url = `${REMOTE_FETCH}?table=Users/${userId}`;
            try {
                const response = await fetch(url, {
                    headers: airTableHeaders,
                });

                // check if the fetch was successful
                if (response.ok) {
                    const userData = await response.json();
                    return userData;
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
        // latLong = 42.3478,-71.0466
        async fetchWeather(latLong) {
            // const url = `${TOMORROW_IO_URL}&location=${latLong}`;
            const url = `${TOMORROW_IO_URL}&location=${latLong}&timesteps=1d&fields=weatherCodeDay,precipitationProbability,temperatureApparentMax`;
            try {
                const response = await fetch(url, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                });

                // check if the fetch was successful
                if (response.ok) {
                    const userData = await response.json();
                    return userData;
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        },
    },
    mounted() {
        // this.fetchMountains();
    },
};
