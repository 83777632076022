<script>
import feather from 'feather-icons';
import MountainsFilter from './MountainsFilter.vue';
import MountainSingle from './MountainSingle.vue';
import fetchMountainsMixin from "@/mixins/fetchMountainsMixin";

export default {
	components: { MountainSingle, MountainsFilter },
  props: {
    showFilters: {
      type: Boolean,
      default: true
    },
    mountainLimit: {
      type: Number,
      required: false,
    },
  },
  mixins: [ fetchMountainsMixin ],
	data: () => {
		return {
			mountainsHeading: 'Ski Mountains',
			selectedPassName: '',
			searchMountain: '',
      filters: {
        uphill: false,
        camping: false,
        region: 'All',
      },
      regions: [
        "Eastern",
        "Mid-Atlantic",
        "Midwest",
        "Northeast",
        "Rockies",
        "Western",
        // Section Break
        "Australia",
        "Austria",
        "Canada",
        "Chile",
        "France",
        "Italy",
        "Japan",
        "New Zealand",
        "Switzerland"
      ]
    };
	},
	computed: {
		// Get the filtered mountains
		filteredMountains() {
      let mtns = this.mountains;
			if (this.selectedPassName) {
        mtns = this.filterMountainsByPassName(mtns);
      }
      if (this.searchMountain) {
        mtns = this.filterMountainsBySearch(mtns);
			}
      if (this.filters.camping) {
        mtns = this.filterMountainsByCamping(mtns);
			}
      if (this.filters.uphill) {
        mtns = this.filterMountainsByUphill(mtns);
			}
      if (this.filters.region !== '' || this.filters.region !== 'all') {
        mtns = this.filterMountainsByRegion(mtns);
			}
			return mtns;
		},
	},
  methods: {
		// Filter mountains by pass name
    filterMountainsByPassName(mtns) {
      return mtns.filter((mountain) => {
        // Ensure "Pass Names" exists and is an array
        if (Array.isArray(mountain['Pass Names'])) {
          // Check if selectedPassName is included in "Pass Names"
          return mountain['Pass Names'].includes(this.selectedPassName);
        }
        return false; // Exclude the mountain if "Pass Names" is not a valid array
      });
    },
    // Filter mountains by title search
		filterMountainsBySearch(mtns) {
			let mountain = new RegExp(this.searchMountain, 'i');
			return mtns.filter((el) => el.Name.match(mountain));
		},
    filterMountainsByCamping(mtns) {
			return mtns.filter((el) =>
        !this.filters.camping || el['Camping? Text'] === 'Yes'
      );
		},
    filterMountainsByUphill(mtns) {
			return mtns.filter((el) =>
        !this.filters.uphill || el['Uphill? Text'] === 'Yes'
    );
		},
    filterMountainsByRegion(mtns) {
			let mountain = new RegExp(this.filters.region, 'i');
      if(this.filters.region === 'All') {
        return mtns
      } else {
        return mtns.filter((el) =>
            Array.isArray(el['Region Names']) && el['Region Names'].includes(this.filters.region)
        );
      }
		},
	},
  mounted() {
    feather.replace();
    this.limit = this.$props.mountainLimit;
    this.fetchMountains();
  },
};
</script>

<template>
	<!-- Mountains grid -->
	<section class="pt-10 sm:pt-14">
		<!-- Mountains grid title -->
		<div class="text-center">
			<p class="font-general-semibold text-2xl sm:text-5xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light">
				{{ mountainsHeading }}
			</p>
		</div>

		<!-- Filter and search mountains -->
    <div v-if="showFilters" class="mt-10 sm:mt-10">
      <h3 class="font-general-regular text-center text-secondary-dark dark:text-ternary-light text-md sm:text-xl font-normal mb-4">
        Search mountains by filter, pass type, location, uphill or allows camping
      </h3>
      <div class="flex flex-wrap justify-between border-b border-primary-light dark:border-secondary-dark pb-3 gap-2">

        <!-- Search Input -->
        <input
            v-model="searchMountain"
            aria-label="Name"
            class="w-full sm:w-1/4 font-general-medium pl-3 pr-1 sm:px-4 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-lg text-sm sm:text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
            id="name"
            name="name"
            type="search"
            required=""
            placeholder="Search Mountains"
        />

        <!-- Mountains Filter Component -->
        <div class="w-full sm:w-1/4">
          <MountainsFilter @filter="selectedPassName = $event" />
        </div>

        <!-- Region Select -->
        <select
            v-model="filters.region"
            class="w-full sm:w-1/4 font-general-medium px-4 py-2 border-1 border-gray-200 dark:border-secondary-dark rounded-lg text-sm sm:text-md bg-secondary-light dark:bg-ternary-dark text-primary-dark dark:text-ternary-light"
        >
          <option disabled value="">Select Region</option>
          <option value="All">All Regions</option>
          <optgroup label="Regions">
            <option
                v-for="region in regions.slice(0, 6)"
                :key="region"
                :value="region">
              {{ region }}
            </option>
          </optgroup>
          <optgroup label="Countries">
            <option
                v-for="country in regions.slice(6)"
                :key="country"
                :value="country">
              {{ country }}
            </option>
          </optgroup>
        </select>

        <!-- Uphill Access Slider -->
        <div class="w-full sm:w-1/4 flex items-center">
          <label for="uphill" class="flex items-center cursor-pointer">
            <div class="relative">
              <input v-model="filters.uphill" type="checkbox" id="uphill" name="uphill" class="sr-only" />
              <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
              <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
            </div>
            <div class="ml-3 text-sm sm:text-md">
              Uphill Access
            </div>
          </label>
        </div>

        <!-- Camping Slider -->
        <div class="w-full sm:w-1/4 flex items-center">
          <label for="camping" class="flex items-center cursor-pointer">
            <div class="relative">
              <input v-model="filters.camping" type="checkbox" id="camping" name="camping" class="sr-only" />
              <div class="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
              <div class="block bg-gray-600 w-14 h-8 rounded-full"></div>
            </div>
            <div class="ml-3 text-sm sm:text-md">
              Camping
            </div>
          </label>
        </div>

      </div>
    </div>

    <!-- Mountains grid -->
    <div v-if="filteredMountains.length"
         class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 mt-6 sm:gap-10">
    <MountainSingle
				v-for="mountain in filteredMountains"
				:key="mountain['ID']"
				:mountain="mountain"
			/>
		</div>
    <div v-else class="text-center py-10">
      <p class="text-lg text-gray-700">No mountains match your criteria.</p>
    </div>
  </section>
</template>

<style scoped>
  input:checked + .dot {
    transform: translateX(100%);
    background-color: white;

    + .block {
      background-color: rgb(99, 102, 241);
    }
  }

  input:checked.block, .dot {
    background-color: white;
  }

  .dot {
    transition: transform 0.3s ease-in-out;
    position: absolute;
  }
</style>
